import React from "react";
import NextHead from "next/head";
import { useColorModeValue } from "@chakra-ui/react";

export const SiteHead: React.FC = () =>  {
	return (
		<NextHead>
			<meta
				key="viewport"
				name="viewport"
				content="width=device-width, initial-scale=1, shrink-to-fit=no"
			/>
			<link rel="preconnect" href="https://fonts.googleapis.com/%22/%3E" />
			<link rel="preconnect" href="https://fonts.gstatic.com/%22/%3E" />
			<link rel="icon" href="https://diskagua.startapp.one/logo.png" />
			<link rel="manifest" href="https://diskagua.startapp.one/logo.png" />
			<link rel="apple-touch-icon" href="https://diskagua.startapp.one/logo.png" />
			<meta name="theme-color" content={useColorModeValue("#49B0D0","blue")} />
		</NextHead>
	);
};

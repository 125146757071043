const blue = {
	50: "#EBF6FA",
	100: "#C6E6F0",
	200: "#A2D7E7",
	300: "#7DC7DE",
	400: "#59B7D4",
	500: "#49B0D0",
	600: "#2A86A2",
	700: "#1F647A",
	800: "#154351",
	900: "#0A2129",
};

const gray = {
	50: "#F4F4F4",
	100: "#E5E5E5",
	200: "#C4C4C4",
	300: "#ADADAD",
	400: "#969696",
	500: "#666666",
	600: "#5E5E5E",
	700: "#4D4D4D",
	800: "#333333",
	900: "#1A1A1A",
};

export const colors = {
	blue,
	primary: blue,
	secondary: gray,
};


import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { AppProps } from "next/app";
import { theme } from "../themes";
import { MainLayout } from "~/layout";
import { bootAPI } from "~/resources/api";
import strings from "~/resources/strings";

const App = (props: AppProps) => {
	const { Component, pageProps } = props;
	return (
		<ChakraProvider theme={theme}>
			<MainLayout>
				<Component {...pageProps} />
			</MainLayout>
		</ChakraProvider>
	);
};

export default App;

bootAPI(strings);

import React from "react";
import { Flex, Image, Text, Stack, SimpleGrid, Box, Divider, Link, Center } from "@chakra-ui/react";
import strings from "resources/strings";
import { categorySection } from "./categorySection";
import { useRouter } from "next/router";
import { onClickGTagEvent } from "~/resources/onClickGTagEvent";

export const Footer: React.FC = () => {
	const componentStrings = strings.components.footer;
	const currentPage = useRouter().asPath;

	return (
		<Box mt={{ base: 32, md: 100 }}>
			<Divider w="93%" m="auto" />
			<Flex
				justifyContent="space-between"
				alignItems="center"
				bgColor="white"
				width={{
					base: "80%",
				}}
				mx="auto"
				py={{ md: 6, lg: 10 }}
				paddingBottom={25}
				marginBottom={{ base: 16, lg: 0 }}
				flexDirection={{ base: "column", lg: "row" }}
			>
				<Flex
					justifyContent="space-between"
					align={{ base: "center", lg: "flex-start" }}
					direction="column"
					maxW={{ base: "100%", lg: "35%" }}
					mt={5}
					fontSize="xl"
				>
					<Image marginBottom={4} alt="Logo" src="/logo-footer.svg" w="100%" />
					<Stack
						direction="column"
						spacing={0}
						mt={1}
						marginBottom={1}
					>
						<Text textAlign={{ base: "center", lg: "start" }} py={3}>
							{componentStrings.company.cnpj}
						</Text>
						<Flex justifyContent={{base: "center", lg: "flex-start"}}>
							<Divider w={{ md:20 ,lg: 61 }}  h={0.5} bgColor="primary.500" borderRadius={50}  />
						</Flex>
						<Text textAlign={{ base: "center", lg: "start" }} py={2}>
							{componentStrings.company.email}
						</Text>
						<Text textAlign={{ base: "center", lg: "start" }}>
							{componentStrings.company.phone}
						</Text>
						<Flex
							paddingTop={10}
							justifyContent={{ base: "center", lg: "flex-start" }}
						>
							<Link href="#">
								<Image pr={6} alt="Instagram" src="/insta.svg" w={53} />
							</Link>
							<Link href="#">
								<Image alt="Facebook" src="/facebook.svg" w={29} />
							</Link>
						</Flex>
					</Stack>
				</Flex>
				<Box mt={10}>
					<SimpleGrid
						columns={1}
						w={{ base: "100%", md: "auto" }}
					>
						{categorySection.map((link, index) => (
							<Flex
								justifyContent={{base: "center", lg: "flex-end"}}
								color="#4A5568"
								fontWeight="350"
								textAlign={{ base: "center", lg: "start" }}
								w="100%"
								my={2}
								key={index}
								fontSize={20}
							>
								<Center display={{ base: "flex", sm: "block" }}>
									<Link
										href={link.link.href}
										color="#49B0D0"
										_active={{fontWeight: "bold"}}
									>
										<Text
											cursor="pointer"
											color="primary.500"
											fontWeight={currentPage === link.link.href ? "bold" : "normal"}
											textAlign="end"
											onClick={()=> onClickGTagEvent(`${link.link} Clicked`, "footer links")}
										>
											{link.name}
										</Text>
									</Link>
								</Center>
							</Flex>
						))}
					</SimpleGrid>
				</Box>
			</Flex>
		</Box>
	);
};

import { ComponentStyleConfig } from "@chakra-ui/react";
import { theme } from "@chakra-ui/theme";

export const Button: ComponentStyleConfig = {
	baseStyle: {
		color: "white",
		fontWeight: "bold",
		borderRadius: "md",
		maxWidth: "450px",
		fontFamily: "poppins",
	},
	defaultProps: {
		variant: "solid",
		colorScheme: "primary",
	},
	variants: {
		outline: (props) => ({
			...theme.components.Button.variants.outline(props),
			colorScheme: `${props.color}.500`,
		}),
		ghost: (props) => ({
			...theme.components.Button.variants.ghost(props),
			border: "none",
		}),
	},
	sizes: {
		base: {
			fontSize: "20px",
		},
	},
};

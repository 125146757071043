import api from "./api";

export class CustomError<ErrorType extends api.ErrorType> {
	public error: ErrorType;
	public message: string;
	constructor(error: ErrorType, message: string) {
		this.error = error;
		this.message = message;
	}
}

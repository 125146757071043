import React from "react";
import { Flex, Image, useMediaQuery, Box } from "@chakra-ui/react";
import { NavbarLinks } from "./NavbarLinks";
import { useRouter } from "next/router";

export const Navbar: React.FC = () => {
	const router = useRouter();

	const [isLargerThan, setIsLargerThan] = React.useState(false);
	const [mediaQuery] = useMediaQuery("(max-width: 1045px)");

	React.useEffect(() => {
		if (mediaQuery !== isLargerThan) {
			setIsLargerThan(mediaQuery);
		}
	}, [mediaQuery, isLargerThan]);

	return (
		<Box>
			{isLargerThan ? (
				<Flex
					bg="primary.500"
					h="118px"
					py={2}
					px={6}
				>
					<Box alignSelf="flex-end">
						<NavbarLinks currentPage={router.asPath} />
					</Box>
					<Box w="135px" m="auto" mb="2">
						<Image
							alt="Disk Água"
							src="/logo.png"
							h="23px"
							w="135px"
						/>
					</Box>
				</Flex>

			) : (
				<Flex
					justifyContent="space-between"
					alignItems="center"
					bg="primary.500"
					h="7.4rem"
				>
					<Flex
						width={{
							base: "90%",
							xl: "80%",
						}}
						justifyContent="space-between"
						marginX="auto"
					>
						<Box>
							<Image
								alt="Disk Água"
								src="/logo.png"
								h="46px"
								w="266px"
							/>
						</Box>
						<NavbarLinks currentPage={router.asPath} />
					</Flex>

				</Flex>
			)}
		</Box>
	);
};

import { ILink, Routes } from "./routes";
import strings from "./strings";

interface INavigationLinks {
	name: string;
	link: ILink;
}

const mainRoutesStrings = strings.routeNames;
export const navigationLinks: INavigationLinks[] = [
	{
		name: mainRoutesStrings.home,
		link: Routes.index(),
	},
	{
		name: mainRoutesStrings.distributors,
		link: Routes.distributors(),
	},
	{
		name: mainRoutesStrings.contactForDistributors,
		link: Routes.contactForDistributors(),
	},
	{
		name: mainRoutesStrings.aboutUs,
		link: Routes.aboutUs(),
	},
	{
		name: mainRoutesStrings.faq,
		link: Routes.faq(),
	},
];

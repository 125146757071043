import { ComponentStyleConfig } from "@chakra-ui/react";

export const Heading: ComponentStyleConfig = {
	baseStyle: {
		color: "primary.500",
		fontFamily: "poppins",
	},
	sizes: {
		base: {
			fontSize: "40px",
		},
	},
	defaultProps: {
		size: "base",
	},
};

import React from "react";
import { Box } from "@chakra-ui/react";
import { Navbar } from "../Navbar";
import { Footer } from "../Footer";
import { SiteHead } from "../SiteHead";

export const MainLayout: React.FC = (props) => {
	const { children } = props;
	return (
		<Box minH="100vh">
			<Navbar />
			<SiteHead />
			{children}
			<Footer />
		</Box>
	);
};

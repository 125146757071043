import React from "react";
import { Text, Box, Flex, Image } from "@chakra-ui/react";
import { LinkButton } from "../../../components/LinkButton";
import strings from "../../../resources/strings";
import { navigationLinks } from "../../../resources";

interface IProps {
	onClose: () => void;
	currentPage: string;
}

export const DrawerContent: React.FC<IProps> = (props) => {
	const { currentPage } = props;

	const { onClose } = props;
	return (
		<Flex
			display="flex"
			flexDirection="column"
		>
			<Flex
				mb="50px"
				mt="72px"
				width="100%"
			>
				<Image
					alt="menu-icon"
					src="/menuIcon.svg"
					onClick={onClose}
					cursor="pointer"
					h="25px"
					width="30px"
					mt="2px"
				/>
				<Image
					alt="Disk Água"
					src="/logo.png"
					h="23px"
					w="135px"
					mx="auto"
					justifySelf="center"
				/>
			</Flex>
			<Flex>
				<Box mb="26px" width="100%" bg="white" h="1px" />
			</Flex>
			{navigationLinks.map((navigation) => (
				<Flex
					key={navigation.name}
					flexDirection="column"
					marginRight="6px"
					mb="22px"
				>
					<LinkButton
						key={navigation.name}
						routeLink={navigation.link}
						onClick={onClose}
						color="white"
					>
						<Text
							cursor="pointer"
							color="white"
							fontSize="20px"
							mr="5"
							bottom={3}
							fontWeight={navigation.link.href === currentPage ? "bold" : "normal"}
						>
							{navigation.name}
						</Text>
					</LinkButton>
				</Flex>
			))}
			<Text
				color="white"
				fontSize="15px"
				onClick={onClose}
				cursor="pointer"
				mt="15px"
			>
				{strings.components.navbar.close}
			</Text>
		</Flex>
	);
};

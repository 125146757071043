import React from "react";
import {
	Box,
	Text,
	Image,
	useMediaQuery,
	Flex,
} from "@chakra-ui/react";
import { DrawerContent } from "../DrawerContent";
import { navigationLinks } from "../../../resources";
import { MobileDrawer } from "~/components/MobileDrawer";
import { LinkButton } from "~/components/LinkButton";

interface IProps {
	currentPage: string;
}

export const NavbarLinks: React.FC<IProps> = (props) => {
	const [isOpen, setIsOpen] = React.useState<boolean>(false);
	const { currentPage } = props;
	const fontSizeBreakPoint = { base: "sm", lg: "xl" };
	const [isLargerThan, setIsLargerThan] = React.useState(false);
	const [mediaQuery] = useMediaQuery("(max-width: 1045px)");

	React.useEffect(() => {
		if (mediaQuery !== isLargerThan) {
			setIsLargerThan(mediaQuery);
		}
	}, [mediaQuery, isLargerThan]);

	return (
		isLargerThan ? (
			<>
				<MobileDrawer
					isOpen={isOpen}
					onClose={() => setIsOpen(false)}
					drawerContentProps={{ borderTopRightRadius: 0, borderTopLeftRadius: 0, bg: "primary.500" }}
				>
					<DrawerContent onClose={() => setIsOpen(false)} currentPage={currentPage} />
				</MobileDrawer>
				<Image
					alt="menu-icon"
					src="/menuIcon.svg"
					onClick={() => setIsOpen(true)}
					cursor="pointer"
					width="30px"
					mb="5px"
					h="25px"
				/>
			</>
		) : (
			<Box display="flex" alignItems="center">
				{navigationLinks.map((navigation) => (
					<Flex
						key={navigation.name}
						flexDirection="column"
						marginRight={2}
					>

						{navigation.link.href === currentPage && (
							<Box
								alignSelf="center"
								mr="5"
								h={1}
								w="100%"
								bg="white"
								position="relative"
								bottom={2}
							/>
						)}
						<LinkButton
							key={navigation.name}
							routeLink={navigation.link}
							color="white"
						>
							<Text
								cursor="pointer"
								color="white"
								fontSize={fontSizeBreakPoint}
								mr="2"
								bottom={3}
								fontWeight={navigation.link.href === currentPage ? "bold" : "normal"}
							>
								{navigation.name}
							</Text>
						</LinkButton>
					</Flex>
				))}
			</Box>
		)
	);
};

/* eslint-disable max-len */
import LocalizedStrings from "localized-strings";

export const strings = new LocalizedStrings({
	ptBr: {
		ogTags: {
			name: "Disk Água",
		},
		error: {
			default: "Um erro ocorreu, tente mais tarde.",
			invalidArgument: "Argumento inválido",
			stillLoading: "Ainda Carregando, aguarde.",
		},
		screen: {
			notFound: {
				title: "Página não encontrada",
				helper: "Você será redirecionado para página principal",
			},
			faq: {
				title: "FAQ",
				faqLeft: [
					{
						title: "Pergunta texto aqui texto aqui?",
						description: "Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo...",
					},
					{
						title: "Pergunta texto aqui texto aqui?",
						description: "Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo...",
					},
					{
						title: "Pergunta texto aqui texto aqui?",
						description: "Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo...",
					},
					{
						title: "Pergunta texto aqui texto aqui?",
						description: "Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo...",
					},
				],
				faqRight: [
					{
						title: "Pergunta texto aqui texto aqui?",
						description: "Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo...",
					},
					{
						title: "Pergunta texto aqui texto aqui?",
						description: "Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo...",
					},
					{
						title: "Pergunta texto aqui texto aqui?",
						description: "Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo...",
					},
					{
						title: "Pergunta texto aqui texto aqui?",
						description: "Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo...",
					},
				],
			},
			receipt: {
				title: "Recibo",
			},
			contact: {
				title: "| Seja Nosso Distribuidor",
				success: "Contato enviado com sucesso!",
			},
			order: {
				validate: {
					name: "Por favor, insira um nome",
					email: "Por favor, insira um email",
					phone: "Por favor, insira um número de telefone",
					street: "Por favor, insira uma rua",
					neighborhood: "Por favor, insira um bairro",
					number: "Por favor, insira um número",
				},
				success: {
					orderCreated: "Pedido realizado com sucesso!",
				},
			},
			aboutUs: {
				description: `
				Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nibh bibendum at nisl egestas neque a nec tellus elementum. Est vulputate aliquet molestie vestibulum, maecenas sit ipsum nam. Blandit integer nec ipsum nisl, accumsan non. Diam curabitur cursus tortor non consectetur quam morbi. Rutrum odio at viverra morbi accumsan, lobortis maecenas sit pellentesque. Leo mauris nulla viverra curabitur turpis justo, nibh. Mauris imperdiet amet elementum sit enim mauris mattis. Sodales risus sit vehicula elementum. Praesent in ac lacus faucibus nisl, mauris ultrices malesuada. Id non tortor id dui. Commodo, nunc quis sed non aliquet. Quis molestie in tincidunt at nunc tellus, 
				quisque ipsum. Dolor ac elementum pulvinar interdum. Vitae, tellus sit venenatis ridiculus euismod. Enim leo porta at duis. Aliquam non convallis sed nullam nisl. Auctor netus at aliquam eget. Aliquet nullam ipsum tincidunt enim facilisis ornare eleifend vitae. Tincidunt semper morbi platea urna diam. Magna leo eget at massa purus mauris diam eu. Pulvinar pellentesque habitasse dictumst nisi. Potenti mauris morbi in facilisi at at quam id. Ullamcorper fringilla velit praesent scelerisque. 
				At nunc vivamus tristique id risus fusce. Turpis fermentum suscipit nisl arcu at gravida urna. Mus lacus, feugiat lacus amet cursus in quis. In ornare eget lobortis lorem fringilla lorem habitant nulla convallis. Tellus eget ut vulputate euismod sed. Quis interdum magna egestas eget nec cursus ut in.
				`,
				title: "| Quem Somos",
			},
		},
		routeNames: {
			home: "Home",
			distributors: "Distribuidores",
			contactForDistributors: "Seja Nosso Distribuidor",
			aboutUs: "Quem somos",
			faq: "FAQ",
		},
		components: {
			footer: {
				titlePage: "Disk Água",
				company: {
					name: "Disk Água",
					email: "diskagua@diskagua.com.br",
					cnpj: "CNPJ: XX. XXX. XXX/0001-XX",
					phone: "71 0000-0000 | 71 9 9999-888",
				},
				links: {
					home: "Home",
					distributors: "Distribuidores",
					ourDistributors: "Seja Nosso Distribuidor",
					aboutUs: "Quem somos",
					faq: "FAQ",
				},
			},
			navbar: {
				close: "Fechar",
			},
			distributors: {
				title: "| Distribuidores",
				buttonBackToStart: "Voltar ao início.",
				seeMore: "Ver mais opções",
			},
			cardPost: {
				readMore: "Ler Mais",
				cardInfo:
					{
						title: "Nome da empresa",
						address: "Descrição",
						tel: "99 99999-9999",
						srcImage: "/imgCard.png",
					},
			},
			contactForm: {
				title: "Seja Nosso Distribuidor",
				name: "Nome:",
				companyName: "Nome da Empresa:",
				contact: "Contato:",
				email: "Email:",
				city: "Cidade:",
				send: "Enviar Solicitação",
			},
			Footer: {
				text: "Desenvolvido pela",
				company: "StartApp",
			},
		},
		stores: {
			erros: {},
		},
	},
});

export default strings;

import React from "react";
import NextLink from "next/link";
import { ILink } from "../../resources/routes";
import { Link, LinkProps } from "@chakra-ui/react";

interface IProps extends LinkProps {
	routeLink: ILink;
}

export const LinkButton: React.FC<IProps> = (props) => {
	const { routeLink, children, ...rest } = props;
	return (
		<NextLink
			href={routeLink.href}
			as={routeLink.as}
		>
			<Link {...rest}>
				{children}
			</Link>
		</NextLink>
	);

};


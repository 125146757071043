export interface ILink {
	href: string;
	as: string;
}

interface IRouteType {
	[key: string]: ((...x: any) => ILink) | ILink | IRouteType;
}

const getRoutes = <T extends IRouteType>(value: T): T => value;

export const Routes = getRoutes({
	index: (params?: string | null) => ({
		href: "/",
		as: `/${params || ""}`,
	}),
	distributors: () => ({
		href: "/distributors/",
		as: "",
	}),
	contactForDistributors: () => ({
		href: "/contactForDistributors/",
		as: "/contactForDistributors",
	}),
	aboutUs: (params?: string | null) => ({
		href: "/aboutUs/",
		as: `/aboutUs/${params || ""}`,
	}),
	faq: (params?: string | null) => ({
		href: "/faq/",
		as: `/faq/${params || ""}`,
	}),
});

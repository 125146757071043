import strings from "~/resources/strings";
import { Routes } from "~/resources";

const mainRoutesStrings = strings.routeNames;

export const categorySection = [
	{
		name: mainRoutesStrings.home,
		link: Routes.index(),
	},
	{
		name: mainRoutesStrings.distributors,
		link: Routes.distributors(),
	},
	{
		name: mainRoutesStrings.contactForDistributors,
		link: Routes.contactForDistributors(),
	},
	{
		name: mainRoutesStrings.aboutUs,
		link: Routes.aboutUs(),
	},
	{
		name: mainRoutesStrings.faq,
		link: Routes.faq(),
	},
];
